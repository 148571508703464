                        </CRow>
<template>
    <div>
      <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :color="'#aa945b'"
        :is-full-page="fullPage">
        </loading>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <b>Not Duplicates</b>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12">
                                <CPagination
                                    @update:activePage="linkGen"
                                    :activePage.sync="activePage"
                                    :pages="pageLength"
                                    align="end"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CDataTable 
                                    :items="possibleDuplicates.duplicates_deleted"
                                    border
                                    :fields="fields"
                                    :noItemsView='{ noResults: "No Questions Found", noItems: "No Questions Found" }'
                                    :tableFilter='{ label : "Filter", placeholder:"Type to Search..." }'
                                    hover
                                    sorter>
                                    <template #index="{item}">
                                        <td>
                                            {{item.id}}
                                        </td>
                                    </template>
                                    <template #quiz_question_1="{item, index}">
                                        <td >
                                            <CRow>
                                                <CCol sm="10">
<!--                                                     <span v-html="getContent(item.quiz_question_1.question_text)"></span>-->
													
                                                  <!--Display Answer options for all the question type-->
                                                  <multipleBlanks  v-if="item.quiz_question_1.question_type == 'fill_in_multiple_blanks_question'" :question="item.quiz_question_1"></multipleBlanks>
                                                  <shortAnswer  v-else-if="item.quiz_question_1.question_type == 'short_answer_question'" :question="item.quiz_question_1"></shortAnswer>
                                                  <matching  v-else-if="item.quiz_question_1.question_type == 'matching_question'" :question="item.quiz_question_1"></matching>
                                                  <trueFalse  v-else-if="item.quiz_question_1.question_type == 'true_false_question'" :question="item.quiz_question_1"></trueFalse>
                                                  <multipleAnswers  v-else-if="item.quiz_question_1.question_type == 'multiple_answers_question'" :question="item.quiz_question_1"></multipleAnswers>
                                                  <multipleChoice  v-else-if="item.quiz_question_1.question_type == 'multiple_choice_question'" :question="item.quiz_question_1"></multipleChoice>
                                                  <multipleDropDowns  v-else-if="item.quiz_question_1.question_type == 'multiple_dropdowns_question'" :question="item.quiz_question_1"></multipleDropDowns>
                                                  <numerical  v-else-if="item.quiz_question_1.question_type == 'numerical_question'" :question="item.quiz_question_1"></numerical>
                                                  <div v-else>{{item.quiz_question_1.question_type}}</div>

                                                  <div style="margin-top:10px" v-if="item.quiz_question_1.tags.length">
                                                      <div style="color:green; font-weight:bold">Tags:</div>
                                                      <span v-for="(tag, t_index) in item.quiz_question_1.tags" :key="t_index" class="mr-2">
                                                          <CBadge color="success" style="font-size:14px; cursor:pointer;margin-top:10px;">{{tag}}</CBadge>
                                                      </span>
                                                  </div>
													
                                                </CCol>
                                            </CRow>
                                        </td>
                                    </template>
                                    <template #quiz_question_2="{item, index}">
                                        <td>
                                            <CRow>
                                                <CCol sm="10">
<!--                                                    <span v-html="getContent(item.quiz_question_2.question_text)"></span>-->
													
                                                  <!--Display Answer options for all the question type-->
                                                  <multipleBlanks  v-if="item.quiz_question_2.question_type == 'fill_in_multiple_blanks_question'" :question="item.quiz_question_2"></multipleBlanks>
                                                  <shortAnswer  v-else-if="item.quiz_question_2.question_type == 'short_answer_question'" :question="item.quiz_question_2"></shortAnswer>
                                                  <matching  v-else-if="item.quiz_question_2.question_type == 'matching_question'" :question="item.quiz_question_2"></matching>
                                                  <trueFalse  v-else-if="item.quiz_question_2.question_type == 'true_false_question'" :question="item.quiz_question_2"></trueFalse>
                                                  <multipleAnswers  v-else-if="item.quiz_question_2.question_type == 'multiple_answers_question'" :question="item.quiz_question_2"></multipleAnswers>
                                                  <multipleChoice  v-else-if="item.quiz_question_2.question_type == 'multiple_choice_question'" :question="item.quiz_question_2"></multipleChoice>
                                                  <multipleDropDowns  v-else-if="item.quiz_question_2.question_type == 'multiple_dropdowns_question'" :question="item.quiz_question_2"></multipleDropDowns>
                                                  <numerical  v-else-if="item.quiz_question_2.question_type == 'numerical_question'" :question="item.quiz_question_2"></numerical>
                                                  <div v-else>{{item.quiz_question_2.question_type}}</div>

                                                  <div style="margin-top:10px" v-if="item.quiz_question_2.tags.length">
                                                      <div style="color:green; font-weight:bold">Tags:</div>
                                                      <span v-for="(tag, t_index) in item.quiz_question_2.tags" :key="t_index" class="mr-2">
                                                          <CBadge color="success" style="font-size:14px; cursor:pointer;margin-top:10px;">{{tag}}</CBadge>
                                                      </span>
                                                  </div>
													
                                                </CCol>

                                            </CRow>
                                        </td>
                                    </template>
									<template #similarity="{item}">
                                        <td class="text-center align-top">
                                            {{Math.round(item.similarity)}}%
                                        </td>
                                    </template>
									<template #updated_at="{item}">
										<td class="text-center align-top">
											{{getHumanDate(item.created_at)}}
										</td>
									</template>
                                    <template #actions="{item, index}">
                                        <td class="text-center align-top">
                                            <button type="button" class="btn btn-success btn-sm" @click="revertDeduplication(item.id)">Revert</button>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCol>

                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CPagination
                                    @update:activePage="linkGen"
                                    :activePage.sync="activePage"
                                    :pages="pageLength"
                                    align="end"
                                />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from "vuex";
import moment from 'moment';

// Components to display different Question Type
import multipleChoice from '../questions/multipleChoice';
import multipleBlanks from '../questions/multipleBlanks';
import multipleAnswers from '../questions/multipleAnswers';
import matching from '../questions/matching';
import shortAnswer from '../questions/shortAnswer';
import multipleDropDowns from '../questions/multipleDropDowns';
import trueFalse from '../questions/trueFalse';
import numerical from '../questions/numerical';

export default {
	components: {
        Loading,
        multipleChoice,
        multipleBlanks,
        multipleAnswers,
        matching,
        shortAnswer,
        multipleDropDowns,
        trueFalse,
        numerical
    },
    computed: {
		...mapState({
		  total: state => state.possibleDuplicates.total_deleted
		}),
        ...mapState(["possibleDuplicates"]),
//        ...mapState({
//            pageLength: state => Math.ceil(state.possibleDuplicates.total_deleted / 30)
//        }),
		pageLength(){
			let length = 0;

			if(this.total <= this.perPage) {
				length = 1
			} else {
				let num = this.total / this.perPage
				length = Math.ceil(num)     
			}
			return length;
		}
    },
    data(){
        return{
            fullPage:true,
            isLoading:false,
            activePage: 1,
			perPage:30,
            fields:[
                { key: 'index', _style:'min-width:10px;width:4%;', label:'SNo'},
                { key: 'quiz_question_1', _style:'min-width:350px;width:35%;', label:'Question 1' },
                { key: 'quiz_question_2', _style:'min-width:350px;width:35%;', label:'Question 2' },
                { key: 'similarity', _style:'min-width:10px;width:8%;', label:'Similarity' },
                { key: 'updated_at', _style:'min-width:10px;width:10%;', label:'Updated At' },
                { key: 'actions', _style:'min-width:10px;width:8%;', label:'' },

            ]
        }
    },
    watch:{
        $route: {
            immediate: true,
            async handler (route) {
                if (route.query && route.query.page) {
                    this.activePage = Number(route.query.page)
                    await this.fetch();
                }
            }
        },
    },
    async mounted(){
        this.isLoading = true
        await this.$store.dispatch("auth/fetchUser");
        await this.fetch();
        this.isLoading = false
    },
    methods:{
		getHumanDate : function (date) {
			return moment(date).format("D/M/YYYY LT");
		},
        async revertDeduplication(id){

            if(id){
                this.isLoading = true
                await this.$store
                .dispatch("possibleDuplicates/revertDeleted", {
                    id: id,
                })
                .then(() => {
                    this.isLoading = false;
                })
                .catch(error => {
                if (error.response) {
                    Vue.notify({
                    group: "notify",
                    type: "error",
                    text: error.response.data.message,
                    max: 5,
                    duration: 5000,
                    speed: 300
                    });
                } else {
                    console.log("Problem submitting New Post", error);
                }
                });
                this.isLoading = false;
            }
 
        },
        async fetch() {
            this.isLoading = true;
            //var stringFilter = encodeURIComponent(JSON.stringify(this.getFilters()));
            await this.$store.dispatch("possibleDuplicates/fetchDuplicatesDeleted", {
                query: this.$route.query
            });


            this.isLoading = false;
        },
        linkGen(pageNum) {
            //alert(pageNum)
            if (this.$route.path === "/deduplication-history") {
            // return {
            //   path: "questions?page=" + pageNum
            // };
                this.$router.push({ query: { page: pageNum }})
            } 
            else 
            {
            this.$router.push({ query: { page: pageNum }})
            }
        },
    }
}

</script>